<template>
  <div>
    <!-- <dashboard-page-title :showSearch="false" :showFilter="false" :showMainActions="false">
    </dashboard-page-title> -->
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <b-row>
          <b-col md="12">
            <div>
              <b-card class="iq-border-radius-10 mb-3">
                <template v-slot:header>
                  <h5 class="text-primary"><i class="las la-file-alt"></i> الاعدادات</h5>
                </template>
                <b-row>
                  <b-col cols="12">
                    <input-form
                        class="mb-3 joining-label"
                        validate="required"
                        name="id"
                        placeholder="نسبه الضريبه"
                        label="نسبه الضريبه"
                        v-model="data.tax_percentage"
                    />
                  </b-col>
                  <!-- <b-col  md="6">
                    <input-form
                        class="mb-3 joining-label"
                        :validate="'required'"
                        name="id"
                        placeholder="Stripe Tax ID"
                        label="Stripe Tax ID"
                        v-model="data.stripe_tax_id"
                    />
                  </b-col> -->
                  <!-- <b-col  md="12">
                    <textarea-form placeholder="الوصف" v-model="data.aboutus" label="عنا"  name="about" validate="required"></textarea-form>
                  </b-col>
                  <b-col  md="12">
                    <textarea-form placeholder="الشروط" v-model="data.terms" label="الشروط"  name="terms" validate="required"></textarea-form>
                  </b-col> -->
                  <b-col  md="12">
                    <input-form
                        class="mb-3 joining-label"
                        :validate="'required'"
                        name="id"
                        placeholder="رابط الفيس بوك"
                        label="رابط الفيس بوك"
                        v-model="data.sociallinks.facebook"
                    />
                  </b-col>
                  <b-col  md="12">
                    <input-form
                        class="mb-3 joining-label"
                        :validate="'required'"
                        name="id"
                        placeholder="رابط تويتر"
                        label="رابط تويتر"
                        v-model="data.sociallinks.twitter"
                    />
                  </b-col>
                  <b-col  md="12">
                    <input-form
                        class="mb-3 joining-label"
                        :validate="'required'"
                        name="id"
                        v-model="data.sociallinks.snap"
                        placeholder="رابط سناب شات"
                        label="رابط سناب شات"
                    />
                  </b-col>
                  <b-col  md="12">
                    <input-form
                        class="mb-3 joining-label"
                        :validate="'required'"
                        name="id"
                        placeholder="رابط انستجرام"
                        label="رابط انستجرام"
                        v-model="data.sociallinks.instagram"
                    />
                  </b-col>
                  <b-col  md="12">
                    <input-form
                        class="mb-3 joining-label"
                        :validate="'required'"
                        name="id"
                        placeholder="رابط جوجل بلاي"
                        label="رابط جوجل بلاي"
                        v-model="data.google_store_link"
                    />
                  </b-col>
                  <b-col  md="12">
                    <input-form
                        class="mb-3 joining-label"
                        :validate="'required'"
                        name="id"
                        placeholder="رابط ابل ستور"
                        label="رابط ابل ستور"
                        v-model="data.apple_store_link"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" md="6" >
                    <label for="aboutus_en">
                      عنا انجليزي
                    </label>
                    <textarea autofocus class="form-control font-weight-bold font-size-60 bg-transparent mb-3"
                      v-model="data.aboutus_en"
                      id="aboutus_en"
                    />
                  </b-col>
                  <b-col cols="12" md="6" >
                    <label for="aboutus_ar">
                      عنا عربي
                    </label>
                    <textarea autofocus class="form-control font-weight-bold font-size-60 bg-transparent mb-3"
                      v-model="data.aboutus_ar"
                      id="aboutus_ar"
                    />
                  </b-col>
                  <b-col cols="12" md="6" >
                    <label for="terms_en">
                      الشروط انجليزي
                    </label>
                    <textarea autofocus class="form-control font-weight-bold font-size-60 bg-transparent mb-3"
                      v-model="data.terms_en"
                      id="terms_en"
                    />
                  </b-col>
                  <b-col cols="12" md="6" >
                    <label for="terms_ar">
                      الشروط عربي
                    </label>
                    <textarea autofocus class="form-control font-weight-bold font-size-60 bg-transparent mb-3"
                      v-model="data.terms_ar"
                      id="terms_ar"
                    />
                  </b-col>
                  <b-col cols="12" md="6" >
                    <label for="privacy_policy_en">
                      سياسة الخصوصية انجليزي
                    </label>
                    <textarea autofocus class="form-control font-weight-bold font-size-60 bg-transparent mb-3"
                      v-model="data.privacy_policy_en"
                      id="privacy_policy_en"
                    />
                  </b-col>
                  <b-col cols="12" md="6" >
                    <label for="privacy_policy_ar">
                      سياسة الخصوصية عربي
                    </label>
                    <textarea autofocus class="form-control font-weight-bold font-size-60 bg-transparent mb-3"
                      v-model="data.privacy_policy_ar"
                      id="privacy_policy_ar"
                    />
                  </b-col>
                  <b-col cols="12">
                      <cropper-images
                          label="صور غلاف الصفحة"
                          nameOfImage="image.jpg"
                          @cropper-save="saveLogoImage"
                          :progressLoading="progressLogo"
                          :showProgress="true"
                          :images="data.cover_page_imgs"
                          @remove-image="removeImage"
                      />
                  </b-col>
                </b-row>
              </b-card>
            </div>
          </b-col>
          <b-col cols="12" v-if="!disabled">
            <b-button variant="primary" type="submit" :disabled="loadingSubmit">
              <span v-if="!loadingSubmit">{{ $t('main.save') }}</span>
              <template v-else>
                <spinner-loading class="d-inline"></spinner-loading>
                <span>{{ $t('main.loading') }}</span>
              </template>
            </b-button>
          </b-col>
        </b-row>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import merchants from '@/modules/marketPlace/services/marketPlace'
import { BASE_API_LINK } from '@/config/constant'

// import citiesAndCountriesMixin from '@/mixins/countriesAndCities'
export default {
  components: { },
  // mixins: [citiesAndCountriesMixin],
  mounted () {
    core.index()
  },
  data () {
    return {
      loadingSubmit: false,
      progressLogo: 0,
      data: {
        aboutus: '',
        terms: '',
        sociallinks: {
          facebook: '',
          twitter: '',
          instagram: '',
          snap: ''
        },
        cover_page_imgs: [],
        stripe_tax_id: '',
        privacy_policy: '',
        tax_percentage: 0,
        google_store_link: '',
        apple_store_link: '',
        aboutus_en: '',
        terms_en: '',
        privacy_policy_en: '',
        aboutus_ar: '',
        terms_ar: '',
        privacy_policy_ar: ''

      }
    }
  },
  methods: {
    onSubmit () {
      this.loadingSubmit = true
      if (!parseFloat(this.data.tax_percentage) || (parseFloat(this.data.tax_percentage) > 100 || parseFloat(this.data.tax_percentage) < 0)) {
        core.showSnackbar('error', 'نسبه الضريبه يجب ان تكون بين 0 و 100')
        this.loadingSubmit = false
        return
      }
      this.data.tax_percentage = parseFloat(this.data.tax_percentage)
      merchants.saveSettings(this.data).then(res => {
        console.log(res.data)
        core.showSnackbar('success', this.$t('main.updatedSuccessfully'))
      }).finally(() => {
        this.loadingSubmit = false
      })
    },
    getSettings () {
      merchants.getSettings().then(res => {
        const data = res.data?.data[0]
        console.log(data)
        this.data = {
          aboutus: data.aboutus,
          terms: data.terms,
          sociallinks: {
            facebook: data.sociallinks?.facebook,
            twitter: data.sociallinks?.twitter,
            instagram: data.sociallinks?.instagram,
            snap: data.sociallinks?.snap
          },
          stripe_tax_id: data.stripe_tax_id,
          tax_percentage: data.tax_percentage,
          privacy_policy: data.privacy_policy,
          google_store_link: data.google_store_link,
          apple_store_link: data.apple_store_link,
          aboutus_en: data.aboutus_en,
          terms_en: data.terms_en,
          privacy_policy_en: data.privacy_policy_en,
          aboutus_ar: data.aboutus_ar,
          terms_ar: data.terms_ar,
          privacy_policy_ar: data.privacy_policy_ar,
          cover_page_imgs: data.cover_page_imgs
        }

        // console.log(this.data)
      })
    },
    saveLogoImage (file) {
      const formData = new FormData()
      formData.append('image', file.image)
      const options = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent
          const percent = Math.floor((loaded * 100) / total)
          this.progressLogo = percent
        }
      }
      this.commonUploadImages(formData, options, 'admin/upload/withdraw-request').then(res => {
        this.data.cover_page_imgs = [...this.data.cover_page_imgs, BASE_API_LINK + res.data.url]
        this.showSuccessUploadFile()
        console.log(this.data.cover_page_imgs)
      }).finally(() => {
        this.progressLogo = 100
      })
    },
    removeImage (event) {
      this.data.cover_page_imgs = this.data.cover_page_imgs.filter(item => item !== event)
    }
  },
  created () {
    this.getSettings()
  }
}
</script>
<style>
.vs__dropdown-toggle {
  background: #fff !important;
  border-radius: 10px !important;
}
</style>
